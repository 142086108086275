import React, { FormEvent, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMachine } from '@xstate/react';

import { IconButtonComponent } from '@components/buttons/IconButton';
import GoogleIconComponent from '@components/icons/googleIcon';
import registrationWebLandingPageStateMachine from '@machines/registrationWebLandingPageStateMachine';
import { ErrorToastComponent } from '@components/web/ErrorToast';
import InputFieldComponent from '@components/web-landingpage/InputField';
import CheckSquareUncheckedComponent from '@components/icons/checkSquareUnchecked';
import CheckmarkIconComponent from '@components/icons/checkmarkIcon';
import AppleIconComponent from '@components/icons/appleIcon';

function RegistrationFormComponent() {
  const [registrationState, sendRegistrationStateEvent] = useMachine(
    registrationWebLandingPageStateMachine,
  );

  const {
    email: emailError,
    password: passwordError,
    terms: termsError,
  } = registrationState.context.state.errors;

  const { email, password, checkedTerms } = registrationState.context.state;

  useEffect(() => {
    if (registrationState.matches('serviceError')) {
      toast.dismiss();
      toast.custom((toast) => (
        <ErrorToastComponent toast={toast}>
          {registrationState.context.state.errors.serviceError}
        </ErrorToastComponent>
      ));
    }
  }, [registrationState.context.state.errors]);

  return (
    <>
      <div className=" tabletLg:block">
        <InputFieldComponent
          id="email"
          placeholder="Your e-mail"
          name="email"
          type="email"
          required={true}
          value={email}
          onChange={(e) => {
            sendRegistrationStateEvent({
              type: 'ENTER_EMAIL',
              email: e.target.value,
            });
          }}
          autoComplete="off"
          errorMessage={emailError}
          onEnter={() => {
            sendRegistrationStateEvent({
              type: 'SUBMIT_FORM',
              data: {
                email,
                password,
                checkedTerms,
              },
            });
          }}
        />
        <InputFieldComponent
          id="password"
          placeholder="Your password"
          name="password"
          type="password"
          required={true}
          value={password}
          onChange={(e) => {
            sendRegistrationStateEvent({
              type: 'ENTER_PASSWORD',
              password: e.target.value,
            });
          }}
          autoComplete="off"
          errorMessage={passwordError}
          onEnter={() => {
            sendRegistrationStateEvent({
              type: 'SUBMIT_FORM',
              data: {
                email,
                password,
                checkedTerms,
              },
            });
          }}
        />
        <RegistrationCheckboxComponent
          name="registration"
          onClick={(e) => {
            sendRegistrationStateEvent({
              type: 'TOGGLE_TERMS',
              checkedTerms: e.target.checked,
            });
          }}
          errorMessage={termsError}
        />
        <div className="flex h-14 flex-col items-center space-y-3">
          <button
            className="flex h-full w-full items-center justify-center rounded-xl border border-transparent bg-primary-default-light px-4 py-2 text-base font-semibold text-text-reversed-light shadow-sm hover:bg-primary-state1-light focus:outline-none"
            onClick={() => {
              sendRegistrationStateEvent({
                type: 'SUBMIT_FORM',
                data: {
                  email,
                  password,
                  checkedTerms,
                },
              });
            }}
          >
            {registrationState.matches('loading')
              ? 'Creating your account ...'
              : 'Sign up and learn for free'}
          </button>
        </div>
      </div>
      <div className="text-marketing-button-secondary-text">
        <IconButtonComponent
          title="Sign in with Google"
          onClick={() => {
            sendRegistrationStateEvent({
              type: 'REGISTER_WITH_GOOGLE',
            });
          }}
        >
          <GoogleIconComponent className="h-6 w-6 flex-shrink-0" />
        </IconButtonComponent>
      </div>
    </>
  );
}

function RegistrationCheckboxComponent({
  name,
  onClick,
  errorMessage,
}: {
  name: string;
  onClick: (e: any) => void;
  errorMessage: string;
}) {
  return (
    <>
      <div className="relative flex items-start">
        <input
          type="checkbox"
          id={name}
          name={name}
          onClick={onClick}
          className="peer absolute top-0 h-6 w-6 cursor-pointer opacity-0"
        />
        <CheckmarkIconComponent className="hidden h-6 w-6 flex-shrink-0 rounded-md bg-marketing-form-surface peer-checked:block"></CheckmarkIconComponent>
        {errorMessage ? (
          <CheckSquareUncheckedComponent className="h-6 w-6 flex-shrink-0 rounded-md border border-marketing-form-error bg-marketing-form-surface text-marketing-form-surface peer-checked:hidden" />
        ) : (
          <CheckSquareUncheckedComponent className="h-6 w-6 flex-shrink-0 rounded-md bg-marketing-form-surface text-marketing-form-surface peer-checked:hidden" />
        )}
        <label
          htmlFor={name}
          className="ml-2 text-xs leading-6 text-marketing-form-text"
        >
          I agree to Mimo&apos;s{' '}
          <a href="https://mimo.org/terms" className="underline decoration-1">
            Terms of Service
          </a>
          .
        </label>
      </div>
      <span className="flex h-6 items-center px-[3px] text-xs text-error-default-light ">
        {errorMessage}
      </span>
    </>
  );
}

export default RegistrationFormComponent;
