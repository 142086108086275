import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useMixpanel } from '@utils/tracking';
import { trackLinkToAppStore, trackLinkToPlayStore } from '@customtypes/events';
import Hero from '/public/images/landingpage-web/hero.png';
import RegistrationForm from '@components/web-landingpage/RegistrationForm';
import { AppStoreBadgeComponent } from '@components/illustrations/AppStoreBadge';
import { PlayStoreBadgeComponent } from '@components/illustrations/PlayStoreBadge';
import { Heading1 } from './Texts';
import { useABTest } from '@hooks/useABTest';
import { ABTestBucket } from '@utils/ab-testing/ab-tests';

export default function HeroComponent() {
  const mixpanel = useMixpanel();
  const experimentHeroBucket = useABTest('experiment-hero-video');

  const [experimentHeroVideo, setExperimentHeroVideo] = useState<
    ABTestBucket | undefined
  >(undefined);

  useEffect(() => {
    setExperimentHeroVideo(experimentHeroBucket);
  }, []);

  function trackPlayStoreLink() {
    trackLinkToPlayStore(mixpanel);
  }

  function trackAppStoreLink() {
    trackLinkToAppStore(mixpanel);
  }

  return (
    <>
      <div className="absolute left-[-90px] top-[50px] z-[0] hidden h-[800px] w-[800px] overflow-hidden bg-[radial-gradient(circle_at_0%_50%,_var(--tw-gradient-stops))] from-[#8d60e266] to-[#25274600] to-[40%] tablet:block"></div>
      <div className="absolute right-0 top-[160px] z-[0] hidden h-[800px] w-[800px] overflow-hidden bg-[radial-gradient(circle_at_100%_50%,_var(--tw-gradient-stops))] from-[#8d60e266] to-[#25274600] to-[40%] tablet:block"></div>

      <div className="relative z-10 space-y-8">
        <div className="grid w-full grid-flow-row grid-cols-1 gap-6 text-marketing-text-primary tablet:gap-5 tabletLg:grid-cols-2 tabletLg:grid-rows-none">
          <div className="flex flex-col items-baseline space-y-10 py-10 tablet:space-y-12 tabletLg:max-w-[430px] tabletLg:justify-center tabletLg:space-y-7 desktop:justify-center">
            <Heading1 className="w-[90%] text-[40px] font-bold leading-[48px] tablet:w-full tablet:text-[48px] tablet:leading-[56px] tabletLg:w-[468px] tabletLg:text-left">
              Learn to code.
              <br />
              Build a portfolio.
              <br />
              Get hired.
            </Heading1>
            <div className="hidden w-full space-y-3 tabletLg:block">
              <RegistrationForm />
            </div>
            <div className="flex h-16 flex-shrink space-x-2 tabletLg:hidden">
              <a
                onClick={trackAppStoreLink}
                target="_blank"
                rel="noreferrer"
                href="https://itunes.apple.com/us/app/mimo-learn-how-to-code-through/id1133960732?mt=8&at=1000lpyT"
              >
                <AppStoreBadgeComponent className="h-12 w-full"></AppStoreBadgeComponent>
              </a>
              <a
                onClick={trackPlayStoreLink}
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.getmimo"
              >
                <PlayStoreBadgeComponent className="h-12 w-full"></PlayStoreBadgeComponent>
              </a>
            </div>
          </div>

          <div
            className={`relative flex min-h-[300px] w-full self-center justify-self-center tablet:h-full tablet:w-full desktop:max-w-[668px]`}
          >
            {experimentHeroVideo === 'variant1_video' ? (
              <div className="flex w-full flex-col items-center justify-center">
                <video
                  autoPlay
                  loop
                  muted
                  src="/videos/hero_video.mp4"
                  className="mx-auto overflow-hidden rounded-2xl object-contain"
                ></video>
              </div>
            ) : (
              <Image
                alt="person engaged in coding on their phone and computer"
                src={Hero}
                priority={true}
                className={`h-full w-full flex-1 rounded-2xl object-contain ${experimentHeroVideo === 'original_image' ? '' : 'opacity-0'}`}
                fill={false}
                placeholder="blur"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
